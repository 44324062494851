module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/builds/touchlessr.com/website/packages/landing-gatsby/src/i18n/messages","languages":["en","pt"],"defaultLanguage":"en","redirect":true,"redirectComponent":"/builds/touchlessr.com/website/packages/landing-gatsby/src/i18n/redirect.js"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-170468774-1","pageTransitionDelay":250,"cookieDomain":"mosano.eu"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/en","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"2f5d4bf41687793aa598afcc67a45457"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
